import { defineStore } from 'pinia'

export const useIamServicesStore = defineStore('useIamServicesStore', {
  state: () => {
    return {
      services: [],
      options: {}
    }
  },

  actions: {
    setServicesToStore (services) {
      const options = {}
      /**
       * Удалить когда добавиться S3 сервис
       */
      services.push({
        name: 's3',
        description: 'S3 service (docs: https://doc.platformcraft.ru/filespot/api/#s3)',
        actions: [
          '*'
        ],
        resource: [
          '*'
        ]
      })

      services.forEach(s => {
        options[s.name] = s
      })

      this.services = services
      this.options = options
    }
  }
})
