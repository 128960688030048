export function formatBytes (bytes, decimals = 2) {
  if (!bytes) return '0 Б'

  const k = 1024
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const size = String(parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))).replace('.', ',')

  return `${size} ${units[i]}`
}

export function formatBytesInSeconds (bandwidth, decimals = 2) {
  if (!bandwidth) return '0 Kbit/sec'

  const units = ['bit/sec', 'Kbit/sec', 'Mbit/sec', 'Gbit/sec']
  let index = 0
  while (bandwidth > 1000 && index < units.length - 1) {
    bandwidth /= 1000
    index++
  }

  return `${bandwidth.toFixed(decimals)} ${units[index]}`
}

export function formatDateTime (datetime) {
  if (!datetime || datetime === '') return ''

  const hours = `0${datetime.getHours()}`.slice(-2)
  const minutes = `0${datetime.getMinutes()}`.slice(-2)
  const day = `0${datetime.getDate()}`.slice(-2)
  const month = `0${datetime.getMonth() + 1}`.slice(-2)
  const year = datetime.getFullYear()

  return `${hours}:${minutes}, ${day}.${month}.${year}`
}

export function parseDuration (ms) {
  let seconds = (ms / 1000).toFixed(0)
  let minutes = Math.floor(seconds / 60)
  let hours = ''

  if (minutes > 59) {
    hours = Math.floor(minutes / 60)
    hours = (hours >= 10) ? hours : `0${hours}`
    minutes = minutes - (hours * 60)
    minutes = (minutes >= 10) ? minutes : `0${minutes}`
  }

  seconds = Math.floor(seconds % 60)
  seconds = (seconds >= 10) ? seconds : `0${seconds}`

  if (hours !== '') {
    return hours + ':' + minutes + ':' + seconds
  }
  return minutes + ':' + seconds
}

export function pluralize (n, word) {
  const cases = [2, 0, 1, 1, 1, 2]
  n = Math.abs(n)
  return word[(n % 100 > 4 && n % 100 < 20) ? 2 : cases[n % 10 < 5 ? n % 10 : 5]]
}

export function joinPath (...args) {
  return args.filter((part) => part).join('/').replace(/\/+/g, '/')
}

export const getTimeStamp = (val) => {
  if (!val || Number.isFinite(val)) return val

  const date = val.match(/(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2}):(\d{2})/)
  let timestamp = null

  if (date && date.length === 7) {
    timestamp = new Date(date[3], date[2] - 1, date[1], date[4], date[5], date[6]).getTime()
  }

  return timestamp
}

export function formatUTCDatetime (val) {
  const year = val.getFullYear()
  const month = `0${val.getMonth() + 1}`.slice(-2)
  const hours = `0${val.getHours()}`.slice(-2)
  const minutes = `0${val.getMinutes()}`.slice(-2)
  const day = `0${val.getDate()}`.slice(-2)

  return `${year}-${month}-${day}T${hours}:${minutes}:00`
}

function pad (number) {
  if (number < 10) {
    return '0' + number
  }
  return number
}

const timeOptions = {
  PT1M: { hour: 'numeric', minute: 'numeric' },
  PT1H: { hour: 'numeric', minute: 'numeric' },
  P1D: { month: 'long', day: 'numeric' },
  P1M: { month: 'short', year: 'numeric' }
}

export function formatDatetimeByGranularity (datetime, granularity) {
  if (granularity === 'PT1M' || granularity === 'PT1H') {
    const time = new Date(datetime)
    time.setHours(time.getHours() + 3)
    return time.toLocaleTimeString('ru', timeOptions[granularity])
  }

  if (granularity === 'P1D') {
    return datetime.toLocaleDateString('ru', timeOptions[granularity])
  }

  return datetime.toLocaleDateString('ru', timeOptions[granularity])
}
