import { defineStore } from 'pinia'

export const useIamPoliciesStore = defineStore('useIamPoliciesStore', {
  state: () => {
    return {
      policies: [],
      policiesInsideAccount: []
    }
  },

  actions: {
    setPoliciesToStore (policies) {
      this.policies = policies
    },

    addPolicyToStore (policy) {
      this.policies.push(policy)
    },

    deletePolicyFromStore (id) {
      const i = this.policies.findIndex(u => u.id === id)
      if (i >= 0) {
        this.policies.splice(i, 1)
      }
    },

    updatePolicyFromStore (policy) {
      const i = this.policies.findIndex(u => u.id === policy.id)
      if (i >= 0) {
        this.policies[i] = policy
      }
    }
  }
})
