import Model from './Model'

const TIMEZONE_OFFSET = '+03:00'

export default class Download extends Model {

  get attributes () {
    return ['id', 'title', 'status', 'lock', 'object_id']
  }

  constructor (data) {
    super(data)

    // 23.12.2019T12:14:15
    const createdAt = (data.time_start || '').match(/(\d+)\.(\d+)\.(\d+)T(.+)/)

    if (createdAt && createdAt.length === 5) {
      const [, day, month, year, time] = createdAt
      this.createdAt = new Date(`${year}-${month}-${day}T${time}${TIMEZONE_OFFSET}`)
    }
  }

  get finished () {
    return this.status === 'Completed'
  }

  get error () {
    return this.status === 'Error'
  }
}
