export const PLAYLIST_EXTENSION = '.m3u8'

export const DVR_RECORD = 1
export const MOTION_RECORD = 2

export const DURATION_HOUR = 60 * 60
export const DURATION_DAY = 24 * 60 * 60
export const DURATION_MINUTE = 60

export const RECORD_BY_CLICK_DURATION = 12 // in hours
export const HOUR_IN_MS = 60 * 60 * 1000
