import { defineStore } from 'pinia'
import { useIamPoliciesStore } from '@/store/iamPolicies.store'

export const useIamPolicyStore = defineStore('useIamPolicyStore', {
  state: () => {
    return {
      policy: null,
      accountPolicy: null,
      accessibleServices: [] // TODO можно брать из $settings
    }
  },

  actions: {
    setPolicyToStore (policy) {
      this.policy = policy
    },

    updatePolicyFromStore (policy) {
      this.policy = policy
      useIamPoliciesStore().updatePolicyFromStore(policy)
    }
  }
})
