/* eslint-disable */

export default {
  'https://rec.platformcraft.ru/{container_id}': function () {
    return [
      {
        "id": "5c8d5174534b4427730ebf7f",
        "name": "new_stream_name",
        "src": "https://example.com/playlist.m3u8",
        "instant_record_id": "",
        "ts": Math.floor(Date.now() / 1000)
      }
    ]
  },
  'https://rec.platformcraft.ru/{container_id}/{id}': function () {
    return {
        "id": "5c8d5174534b4427730ebf7f",
        "name": "new_stream_name",
        "src": "https://example.com/playlist.m3u8",
        "instant_record_id": "",
        "ts": Math.floor(Date.now() / 1000),
        "qualities": {
          "https://example.com/playlist.m3u8": "360p",
          "https://example.com/playlist2.m3u8": "480p"
        }
    }
  },
  'https://rec.platformcraft.ru/{container_id}/{streamId}/record': function () {
    return [{
      "id": "5cc440f0534b4412ae69d6e3",
      "src": "https://example.com/playlist.m3u8",
      "files": null, // массив id созданных видеофайлов
      "start": 1556305586,
      "stop": 1556306586,
      "max_dur": 3600,
      "status": "wait", // статус записи (wait - ожидание, progress - выполнение, finish - запись завершена, error - произошла ошибка)
      "msg": "", // сообщение сервиса записи (в случае ошибки)
      "email": "test@test.te",
      "is_instant": false, // is_instant = true - мгновенная запись, is_instant = false - запись по расписанию
      "stop_timeout": 600,
      "motion_record": true,
      "events_record": false
    }]
  }
}
