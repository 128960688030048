import Model from './Model'

const TIMEZONE_OFFSET = '+03:00'

export default class File extends Model {
  get attributes () {
    return [
      'id',
      'name',
      'path',
      'content_type',
      'size',
      'previews',
      'preview_url',
      'private',
      'description',
      'video_info',
      'advanced',
      'hls',
      'perms'
    ]
  }

  constructor (data) {
    super(data)

    // 23.12.2019T12:14:15
    const createdAt = (data.create_date || '').match(/(\d+)\.(\d+)\.(\d+)T(.+)/)

    if (createdAt && createdAt.length === 5) {
      const [, day, month, year, time] = createdAt
      this.createdAt = new Date(`${year}-${month}-${day}T${time}${TIMEZONE_OFFSET}`)
    }

    this.checked = false
    this.checkedTime = 0
  }

  get isDir () {
    return !this.contentType
  }

  set checked (state) {
    state = !!state
    this.__checked = state
    this.checkedTime = state ? Date.now() : 0
  }

  get checked () {
    return this.__checked
  }

  get isImage () {
    return ['image/jpeg', 'image/png', 'image/webp'].indexOf(this.contentType) > -1
  }

  get isVideo () {
    return this.contentType.startsWith('video/')
  }

  get isHls () {
    return this.contentType.startsWith('application/') && this.name.substr(-5) === '.m3u8'
  }

  get isAudio () {
    return this.contentType === 'audio/mpeg'
  }

  get subtitles () {
    return this.videoInfo && this.videoInfo.tracks ? this.videoInfo.tracks : undefined
  }

  get adUrl () {
    return this.videoInfo ? this.videoInfo.vast_ad_tag_url : null
  }

  get tags () {
    return this.videoInfo ? this.videoInfo.tags : null
  }

  get qualities () {
    if (!this.isVideo) {
      return
    }

    if (this.videoInfo && this.videoInfo.videos) {
      return this.videoInfo.videos.map((video) => {
        if (!video.advanced || !video.advanced.video_streams.length) {
          return ''
        }
        return `${video.advanced.video_streams[0].height}p`
      }).join(', ')
    } else {
      if (!this.advanced || !this.advanced.video_streams.length) {
        return ''
      }
      return `${this.advanced.video_streams[0].height}p`
    }
  }

  get downloadUrl () {
    if (!this.private) {
      return this.__data.download_url
    }
  }

  get origin () {
    return this.__data.download_url.split('/')[0]
  }

  updatePath (name) {
    this.path = this.path.split('/').slice(0, -1).concat([name]).join('/')
  }

  get directory () {
    return this.path.split('/').slice(0, -1).join('/')
  }
}
