import { defineStore } from 'pinia'
import { useIamGroupsStore } from '@/store/iamGroups.store'

export const useIamGroupStore = defineStore('useIamGroupStore', {
  state: () => {
    return {
      group: {
        id: '',
        name: '',
        users: [],
        roles: []
      }
    }
  },

  actions: {
    setGroupToStore (group) {
      this.group = group
    },

    updateGroupFromStore (group) {
      this.group = group
      useIamGroupsStore().updateGroupFromStore(group)
    }
  }
})
