import { createRouter, createWebHistory } from 'vue-router'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import Login from '@/pages/Login'
import api from '@/utils/api'
import Register from '@/pages/Register'
import Restore from '@/pages/Restore'
import Recovery from '@/pages/Recovery'

export const routes = [
  {
    path: '/en/:path(.*)?',
    component: LanguageSwitcher,
    props: { functional: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { guest: true }
  },
  {
    name: 'activate',
    path: '/login/activate/:id',
    component: Login,
    meta: { guest: true }
  },
  {
    name: 'restore',
    path: '/login/restore',
    component: Restore,
    meta: { guest: true }
  },
  {
    name: 'pwrestore',
    path: '/login/restore/:code',
    component: Recovery,
    meta: { guest: true }
  },
  {
    name: 'register',
    path: '/register/:status(ready)?',
    component: Register,
    props: { functional: true },
    meta: { guest: true }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('./pages/HomePage.vue')
  },
  {
    name: 'search',
    path: '/search',
    meta: { root: '/storage' },
    component: () => import('./pages/SearchPage.vue')
  },
  {
    name: 'settings',
    path: '/settings/:page([^/]*)?',
    props: true,
    meta: { root: '/settings' },
    component: () => import('./pages/SettingsPage.vue')
  },
  {
    name: 'storage',
    path: '/storage/:path(.*)?',
    props: true,
    meta: { root: '/storage' },
    component: () => import('./pages/StoragePage.vue')
  },
  {
    name: 'streams',
    path: '/streams/:id?',
    props: true,
    meta: { storageRoot: '/storage' },
    component: () => import('./pages/StreamsPage.vue')
  },
  {
    name: 'tasks',
    path: '/tasks',
    props: true,
    meta: { root: '/tasks' },
    component: () => import('./pages/TasksPage.vue')
  },
  {
    name: 'feedback',
    path: '/feedback',
    props: true,
    meta: { root: '/feedback' },
    component: () => import('./pages/FeedbackPage.vue')
  },
  {
    name: 'cdn-stats',
    path: '/cdn-stats',
    props: true,
    meta: { root: '/cdn-stats' },
    component: () => import('./pages/CdnStatsPage.vue')
  },
  {
    name: 'S3',
    path: '/s3/:path(.*)?',
    props: true,
    meta: { root: '/s3' },
    component: () => import('./pages/S3Page.vue')
  },
  {
    path: '/:pathMatch(.*)*', component: () => import('./pages/NotFoundPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const guestRoute = to.meta.guest

  if (!guestRoute) {
    if (api.token) {
      next()
    } else {
      api.refreshToken().then(() => {
        next()
      }).catch(() => {
        next({
          name: 'login'
        })
      })
    }
  } else {
    next()
  }
})

export default router
