import { defineStore } from 'pinia'
import { useIamSubusersStore } from '@/store/iamSubusers.store'

const defaultSubuser = {
  id: '',
  userId: '',
  login: '',
  groups: [],
  roles: [],
  primaryGroup: ''
}

export const useIamSubuserStore = defineStore('useIamSubuserStore', {
  state: () => {
    return {
      subuser: defaultSubuser
    }
  },

  actions: {
    setSubuserToStore (subuser) {
      this.subuser = subuser
    },

    updateSubuserFromStore (subuser) {
      this.subuser = subuser
      useIamSubusersStore().updateSubuserFromStore(subuser)
    },

    setDefaultSubuserToStore () {
      this.subuser = {
        id: '',
        userId: '',
        login: '',
        groups: [],
        roles: [],
        primaryGroup: ''
      }
    }
  }
})
