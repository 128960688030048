export default class Model {
  constructor (data) {
    this.update(data)
  }

  update (data) {
    this.__data = data
    this.attributes.forEach((attribute) => {
      // convert attribute name to camelCase
      const name = attribute.replace(/_(.)/g, (match) => match[1].toUpperCase())
      this[name] = data[attribute]
    })
  }

  get attributes () {
    return []
  }
}
