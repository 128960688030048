import './registerServiceWorker'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import i18n from '@/utils/i18n.util'
import { formatDateTime } from '@/utils/format.util'
import api from '@/utils/api'
import eventBus from './utils/eventBus.util.js'

import Toast from '@/components/Toast/Toast'
import Notification from '@/components/Toast/Notification'
import Modal from '@/components/Modals/Modal'
import { createPinia } from 'pinia'
import user from '@/data/user'

const pinia = createPinia()

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(Toast)
  .use(Notification)
  .use(Modal)
  .use(pinia)

app.config.globalProperties.$filters = { dateTime: formatDateTime }
app.config.globalProperties.$api = api
app.config.globalProperties.$bus = eventBus
app.config.globalProperties.$user = user

app.mount('#app')
