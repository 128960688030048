import en from '../translations/en'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: 'ru',
  allowComposition: true,
  silentTranslationWarn: true,
  messages: { en }
})

export default i18n
