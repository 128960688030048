import { defineStore } from 'pinia'

export const useIamRolesStore = defineStore('iamRolesStore', {
  state: () => {
    return {
      roles: []
    }
  },

  actions: {
    setRolesToStore (roles) {
      this.roles = roles
    },

    addRoleToStore (role) {
      this.roles.push(role)
    },

    deleteRoleFromStore (id) {
      const i = this.roles.findIndex(u => u.id === id)
      if (i >= 0) {
        this.roles.splice(i, 1)
      }
    },

    updateRoleFromStore (role) {
      const i = this.getRoleIndex(role.id)
      if (i) {
        this.roles[i] = role
      }
    },

    getRoleIndex (id) {
      const i = this.roles.findIndex(u => u.id === id)
      return i >= 0 ? i : null
    }
  }
})
