import { defineStore } from 'pinia'
import { useIamRolesStore } from '@/store/iamRoles.store'

export const useIamRoleStore = defineStore('useIamRoleStore', {
  state: () => {
    return {
      role: {
        id: '',
        name: '',
        users: [],
        groups: []
      }
    }
  },

  actions: {
    setRoleToStore (role) {
      this.role = role
    },

    updateRoleFromStore (role) {
      this.role = role
      useIamRolesStore().updateRoleFromStore(role)
    }
  }
})
