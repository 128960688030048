import { defineStore } from 'pinia'
import { S3_MAX_KEYS_LIMIT } from '@/configs/config'

export const useS3Store = defineStore('S3Store', {
  state: () => {
    return {
      S3: null,
      objects: [],
      params: {
        Bucket: null,
        Delimiter: '/',
        MaxKeys: S3_MAX_KEYS_LIMIT,
        Prefix: null
      }
    }
  },

  actions: {
    setS3ToStore (data) {
      this.S3 = data
    },
    setObjectsToStore (objects) {
      this.objects = objects
    },

    addObjectToStore (object) {
      this.objects.push(object)
    },

    removeObjectFromStore (name) {
      const i = this.objects.findIndex(b => b.name === name)

      if (i >= 0) {
        this.objects.splice(i, 1)
      }
    },

    removeObjectFromStoreByKey (key) {
      const i = this.objects.findIndex(b => b.key === key)

      if (i >= 0) {
        this.objects.splice(i, 1)
      } else {

        /**
         * try remove folder
         */
        const i = this.objects.findIndex(b => b.key === key + '/')
        if (i >= 0) {
          this.objects.splice(i, 1)
        }
      }
    }
  }
})
