import { defineStore } from 'pinia'

export const useIamStore = defineStore('IamStore', {
  state: () => {
    return {
      breadcrumbs: ['IAM'],
      iamMode: 'list'
    }
  },

  actions: {
    setCrumbsToStore (crumbs) {
      this.breadcrumbs = crumbs
    },

    setMode (mode = 'list') {
      this.iamMode = mode
    },

    addCrumbToStore (crumb) {
      this.breadcrumbs = [...this.breadcrumbs, crumb]
    }
  }
})
