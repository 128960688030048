import { defineStore } from 'pinia'

export const useIamSubusersStore = defineStore('iamSubusersStore', {
  state: () => {
    return {
      subusers: []
    }
  },

  actions: {
    setSubusersToStore (subusers) {
      this.subusers = subusers
    },

    addSubuserToStore (subusers) {
      this.subusers.push(subusers)
    },

    removeSubuserFromStore (id) {
      const i = this.subusers.findIndex(u => u.id === id)
      if (i >= 0) {
        this.subusers.splice(i, 1)
      }
    },

    updateSubuserFromStore (user) {
      const i = this.subusers.findIndex(u => u.id === user.id)
      if (i >= 0) {
        this.subusers[i] = user
      }
    }
  }
})
