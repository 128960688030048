import { defineStore } from 'pinia'

export const useIamGroupsStore = defineStore('iamGroupsStore', {
  state: () => {
    return {
      groups: []
    }
  },

  actions: {
    setGroupsToStore (groups) {
      this.groups = groups
    },

    addGroupToStore (group) {
      this.groups.push(group)
    },

    deleteGroupFromStore (id) {
      const i = this.groups.findIndex(u => u.id === id)
      if (i >= 0) {
        this.groups.splice(i, 1)
      }
    },

    updateGroupFromStore (group) {
      const i = this.groups.findIndex(u => u.id === group.id)
      if (i >= 0) {
        this.groups[i] = group
      }
    }
  }
})
